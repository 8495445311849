$light: #459399;
$dark: #313c4d;

.lottie-loader {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.resume-container {
    margin-top: -126px;
    position: relative;

    .resume-section {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-gap: 30px;
    }

    .rewards {
        list-style: hebrew;
    }

    .interests {
        display: inline-flex;
        padding: 6px 10px;
        border: 2px solid $dark;
        color: $dark;
        margin: 10px;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
    }

    .download-resume {
        position: fixed;
        bottom: 20px;
        background-color: #fff;
        padding: 10px 20px;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0px 0px 4px 1px #747474;
        border-radius: 20px;
        font-weight: 600;
        transition: all ease-in-out 0.2s;
        text-decoration: none;
        color: inherit;
        cursor: pointer;
        display: flex;
        align-items: center;

        >div {
            margin-right: 10px;
        }

        &:hover {
            font-weight: 700;
            box-shadow: 0px 0px 10px 3px #747474;
        }
    }

    .resume-inner-container {
        padding: 30px;
        width: 80vw;
        border: 1px solid #fff;
        border-radius: 10px;
        margin: 166px auto 60px;
        // color: #fff;
        background-color: #fff;
        color: $dark;

        &.download {
            width: 100vw;
            margin-top: 126px;
            border-radius: 0px;
            box-sizing: border-box;
            margin-bottom: 0px;
            padding: 40px 80px 80px;
            font-size: 20px;

            .font-14 {
                font-size: 20px;
            }

            .font-18 {
                font-size: 24px;

                li {
                    font-size: 20px;
                }
            }

            .designation {
                margin-bottom: 10px;
            }

            .skills {
                font-size: 18px !important;
            }

            .seperator-title {
                font-size: 26px;
            }

            .portfolio-link {
                margin-bottom: 20px;
                font-weight: 500;
                color: $light !important;

                >span {
                    font-size: 20px;
                    margin-right: 5px;
                }
            }
        }

        a {
            color: inherit;
            text-decoration: none;
            display: flex;
            align-items: center;
            transition: all ease-in 0.1s;

            &:hover {
                font-weight: 600;
            }
        }

        .content-1 {
            display: flex;
            align-items: flex-start;

            >div {
                &:nth-child(1) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 15%;

                    img {
                        height: 150px;
                        width: 150px;
                        border: 3px solid;
                        border-radius: 50%;
                        margin-top: 30px;
                        object-fit: contain;
                        transition: all ease-in 0.2s;

                        &:hover {
                            box-shadow: 0px 0px 10px 5px $light;
                        }
                    }
                }

                &:nth-child(2) {
                    margin-left: 30px;
                    width: 55%;

                    h1 {
                        margin-bottom: 5px;
                    }

                    p {
                        margin-top: 0px;
                    }
                }

                &:nth-child(3) {
                    margin-left: 30px;
                    width: 30%;
                    background-color: $dark;
                    border-radius: 10px;
                    color: #fff;
                    padding: 20px 40px;

                    a {
                        >span {
                            margin-right: 10px;
                        }

                        >img {
                            height: 24px;
                            margin-right: 10px;
                            filter: invert(1);
                        }
                    }

                    ul {
                        list-style: none;
                        margin: unset;
                        padding: unset;

                        li {
                            margin: 16px 0px;
                        }
                    }
                }
            }
        }

        .content-2 {
            .skills {
                display: inline-flex;
                padding: 6px 10px;
                background-color: $dark;
                color: #fff;
                margin: 10px;
                border-radius: 8px;
                font-size: 18px;
                font-weight: 600;
            }
        }

        .content-3 {
            .eduction-container {
                margin: 0px 0px 20px 20px;

                .company {
                    display: flex;
                    align-items: baseline;

                    a {
                        margin-right: 10px;
                        text-decoration: underline;
                    }

                    .tech-stack {
                        margin-left: 10px;
                        font-weight: 500;
                        font-size: 16px;
                        color: $light
                    }
                }

                .project-wrapper {
                    margin-top: 20px;
                    padding-left: 0px;
                    list-style: none;

                    .projects-container {
                        .project-name {
                            display: flex;
                            align-items: center;
                            margin-bottom: 6px;

                            >span {
                                &:first-child {
                                    font-size: 18px;
                                    margin-right: 10px;
                                }
                            }
                        }

                        ul {
                            font-size: 16px;
                            font-weight: 500;
                            list-style: square;

                            li {
                                margin-bottom: 10px;

                                &::marker {
                                    color: $light;
                                }
                            }
                        }

                    }


                }

            }
        }

    }
}

.margin-l-6 {
    margin-left: 6px;
}

.margin-r-6 {
    margin-right: 6px;
}

.seperator {
    display: flex;
    align-items: center;
    margin: 30px 0px;

    .seperator-title {
        font-size: 24px;
        font-weight: 600;
        display: inline-flex;
        white-space: nowrap;
    }

    .icon-container {
        background-color: $dark;
        display: inline-flex;
        padding: 6px;
        color: #fff;
        border-radius: 6px;
        position: relative;
        margin: 0px 10px 0px 20px;

        &::before {
            content: "";
            height: 25px;
            width: 10px;
            background-color: $light;
            position: absolute;
            left: -12px;
            border-radius: 2px;
        }

        &::after {
            content: "";
            height: 20px;
            width: 10px;
            background-color: $light;
            position: absolute;
            left: -24px;
            top: 8px;
            border-radius: 2px;
        }
    }

    .line-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .line {
            display: inline-flex;
            border-radius: 4px;
            width: 30%;
            height: 6px;
            background-color: $light;

            &.dark {
                background-color: $dark;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .resume-inner-container {
        padding: 10px !important;
        width: 95vw !important;
        margin: 128px auto 60px !important;
    }

    .content-1 {
        flex-direction: column;

        .image {
            margin-top: 0px !important;
        }

        h1 {
            margin-top: 0px !important;
        }

        h1,
        .designation,
        .description {
            text-align: center;
        }

        >div {
            width: 100% !important;
            margin-left: 0px !important;
            padding: 10px !important;
            box-sizing: border-box;
        }
    }

    .projects-container {
        margin-left: 10px !important;

        ul {
            padding-left: 20px !important;
        }
    }

    .seperator-title {
        font-size: 5vw !important;
    }
}

.primary {
    color: $light;
}

.fw-800 {
    font-weight: 800;
}

.fw-600 {
    font-weight: 600;
}

.fw-500 {
    font-weight: 500;
}


.margin-b-6 {
    margin-bottom: 6px;
}