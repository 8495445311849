.list {
    list-style: devanagari;
    font-size: 1.5vw;
    line-height: 2;
    cursor: pointer;

    a {
        text-decoration: none;
        color: inherit;
    }

    li {
        position: relative;

        &::after {
            height: 2px;
            position: absolute;
            content: "";
            width: 0;
            background-color: #fff;
            z-index: 2;
            left: 0;
            bottom: 5px;
            transition: all ease-in-out 0.2s;
        }

        &:hover {
            &::after {
                width: 105px;
            }
            img {
                filter: unset;
                background-color: #fff;
                border-radius: 4px;
            }
        }
    }
    img {
        height: 20px;
        width: 20px;
        margin-left: 5px;
        transition: all ease-in 0.2s;
        filter: invert(1);
    }
}

@media only screen and (max-width: 700px) {
    .list {
        font-size: 3vw !important;
        li {
            &:hover {
                &::after {
                    width: 0;
                }
            }
        }
        img {
            height: 10px;
            width: 10px;
            margin-left: 0px;
        }
    }

}