.contact {
    display: flex;
    height: unset;
    box-sizing: border-box;
    background-image: radial-gradient(circle, #1e0d0d, #181818, #1d1c1c);
    color: #ffff;
    // position: absolute;
    width: 100vw;
    // top: 0;
    margin-top: -126px;
    padding: 10vw 6vw 4vw 0px;

    .contact-heading {
        position: absolute;
        height: unset;
        width: 100vw;

        .marquee__content {
            margin-top: 20px;
            margin-bottom: 20px;

            li {
                font-size: 10vw;

                &.light {
                    color: #7c7878;
                }
            }
        }
    }

    .description {
        padding-left: 12vw;
    }

    .contact-form {
        width: 35%;
        padding: 20px;
        box-sizing: border-box;
        z-index: 1;
        background: #2a2727a6;
        border-radius: 30px;
        &.success {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            
            >div, .sucess-thumb {
                margin-bottom: 4vw;
                text-align: center;
                font-size: 2vw;
                &:first-child {
                    font-size: 4vw;
                }
            }
        }
    }

    .contact-text {
        margin-top: 23vw;
        width: 65%;
        font-size: 3vw;

        h2 {
            margin: unset;
        }
    }

    .contact-btn {
        margin-top: 25px;
        padding: 20px 40px;
        border-radius: 10px;
        width: 100%;
        border: 1px solid #fff;
        font-size: 2rem;
    }
}


@media only screen and (max-width: 700px) {
    .contact {
        flex-direction: column;
        padding-right: unset;
        overflow: scroll;
        margin-top: -45px;
        // height: unset;

        .contact-text {
            width: 100%;

            .description {
                font-size: 8vw;
            }
        }

        .contact-form {
            width: 100%;
            margin-top: 20px;
            border-radius: unset;

            input,
            textarea {
                font-size: 5vw;
            }
        }

    }

    .contact-btn {
        width: 100% !important;
        margin-top: 10px !important;
    }

    .marquee {
        width: 100vw !important;
        box-sizing: border-box !important;
    }

}