.new-header {
    position: sticky;
    top: 0;
    color: #ffff;
    z-index: 1;
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    box-sizing: border-box;
    background: linear-gradient(90deg, #212121e3 0%, #0d0b0bb5 50%, #0c0404ab 100%);

    a {
        text-decoration: unset;
        color: inherit;
    }

    .name {
        display: flex;
        align-items: center;
        font-size: 2vw;
        font-weight: 600;

        img {
            height: 46px;
            margin-right: 20px;
            filter: invert(1);
        }
    }

    .menu {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 2vw;
        color: #8d8d8d;
        line-height: 1;

        >span:first-child {
            margin-right: 6px;
        }

        &:hover {
            color: #ffff;

            .material-symbols-outlined {
                transform: rotate(90deg);
            }
        }

    }

}

.menu-option-wrapper {
    position: fixed;
    z-index: 99;
    top: 0;
    height: 0;

    .backdrop {
        position: fixed;
        z-index: -1;
        top: 0;
        height: 100vh;
        width: 100vw;
        background-color: #ffffff3d;
    }

    .menu-option {
        width: 100vw;
        background-color: #161616fa;
        box-sizing: border-box;
        color: #fff;
        display: flex;
        justify-content: space-between;
        padding: 52px 110px 70px;
        font-size: 2vw;
        z-index: 99;
        transition: transform 0.3s ease-in;
        box-shadow: 0px 3px 9px 0px #232323;
        transform: translateY(-100%);
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;

        &.open {
            transform: translateY(0);
        }
    }

    ul {
        margin: unset;
        line-height: 1.5;

        li {
            list-style: none;
            position: relative;
            color: #8d8d8d;
            transition: all ease-in 0.1s;
            cursor: pointer;

            &:hover {
                color: #ffff;

                &::before {
                    position: absolute;
                    left: -130px;
                    content: "Profile /";
                }
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .new-header {
        padding: 10px;

        .name {
            font-size: 3vw;
        }

        .name img {
            height: 25px;
        }

        .menu {
            font-size: 3vw;

            .material-symbols-outlined {
                font-size: 12px;
            }
        }
    }

    .menu-option-wrapper {
        bottom: 0;
        top: unset;
        width: 100vw;

        .menu-option {
            font-size: 4vw;
            border-radius: unset;
            border-top-right-radius: 30px;
            border-top-left-radius: 30px;
            box-shadow: 0px -3px 9px 0px #232323;
            padding: 20px;

            ul {
                line-height: 2.5;
                padding-left: 25px;
            }

            transform: translateY(0);

            &.open {
                transform: translateY(-100%) !important;
            }

            .material-symbols-outlined {
                font-size: 22px;
                height: 10px;
            }
        }
    }

}