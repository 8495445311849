.about-me {
    // position: absolute;
    top: 0;
    padding-top: 12vw;
    color: #ffff;
    height: unset;
    width: 100vw;
    box-sizing: border-box;
    margin-top: -126px;
    // overflow: scroll;
    background-image: repeating-linear-gradient(150deg, #141414 50%, #212121 60%, #040101 60%);

    .title {
        padding: 4vw 10vw;
        font-size: 6vw;
        font-weight: 800;
    }

    .description-box {
        display: flex;
        padding: 2vw 6vw;

        .description-img {
            width: 55vw;
            margin-right: 40px;

            img {
                width: inherit;
                height: 400px;
                border-radius: 20px;
                box-shadow: 0px 0px 6px 2px #535252;
            }
        }

        .description {
            font-size: 2.3vw;
            font-weight: 500;
            width: 45vw;
            color: #b9b7b7;
            box-sizing: border-box;
        }
    }

    .work-ex {
        margin: 4vw 8vw;
        border: 1px solid #666666;
        padding: 2vw 4vw;
        border-radius: 3vw;
        background-image: radial-gradient(circle, #18272c, #181818, #1d1c1c);
        transition: all ease-in 0.3s;

        &:hover {
            box-shadow: inset 0px 0px 8px 4px #666666;
        }

        ul {
            margin: 0px;
            padding: 0px;
            line-height: 3;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                list-style: none;
                padding: 0;
                font-size: 2.5vw;
                font-weight: 600;

                &:not(:last-child) {
                    border-bottom: 1px solid #b9b7b7;
                }

                .light {
                    font-size: 1.6vw;
                    color: #939393;
                }
            }
        }
    }

    .lets-talk {
        background-color: #151515;
        padding: 8vw 0px;

        .talk-title {
            text-align: center;
            font-size: 4vw;
            margin: unset;
        }

        .mail-id {
            margin: 4vw 0px 8vw;
            text-align: center;
            transition: all ease-in-out 0.3s;

            &:hover {
                transform: scale(1.05);
            }

            a {
                text-decoration: none;
                color: inherit;
                font-size: 8vw;
            }
        }
    }

    hr {
        border: 1px solid rgb(58, 58, 58);
    }

    .social-media {
        margin-top: 8vw;

        .list {
            font-size: 1.5rem;
            padding: 0;
            line-height: 2;
            cursor: pointer;
            list-style-type: none;
            display: flex;
            justify-content: space-around;
        }
    }

    .work-process {
        margin-top: 40px;
        padding: 6vw 6vw 8vw;
        background: radial-gradient(circle at 100%, #333, #333 50%, #666363 75%, #333 75%);
    }

    .about-me-heading {
        height: unset;
        margin-top: 30px;
        background: #000000;
        border-top: 6px solid;
        border-bottom: 6px solid;

        .marquee__content li {
            font-size: 3rem;

            &.light {
                color: #333;
            }
        }
    }
}


@media only screen and (max-width: 700px) {
    .about-me {
        margin-top: -45px;
    }

    .description-box {
        flex-direction: column;

        .description-img {
            >img {
                height: 200px !important;
            }

            width: 100% !important;
            margin-right: unset !important;
        }

        .description {
            margin-top: 20px !important;
            font-size: 6vw !important;
            width: 100% !important;
        }
    }

    .work-ex {
        margin: 4vw !important;
        padding: 2vw !important;

        h1 {
            font-size: 4vw;
        }

        li {
            font-size: 3vw !important;

            .light {
                font-size: 2vw !important;
            }
        }
    }

    .work-process {
        h3 {
            padding: 3vw 6vw 0vw;
        }

        .description {
            padding: 0vw 6vw 6vw;
        }

        padding: unset !important;
        padding-bottom: 8vw !important;

        h1 {
            padding: 0vw 6vw 0vw;
        }
    }
}