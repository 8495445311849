.blogs-container {
    height: 100vh;
    background: radial-gradient(circle at 50%, #38454c, #161c21 0%, #1c1212 44%, #2c2c2c 90%);
    .marquee__content { 
        li {
            color: #b7afaf;
            &.light {
                color: #9a7f6e;
            }
        }
    }
}