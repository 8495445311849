.container {
    display: flex;
    align-items: center;
    justify-content: center;
    >.material-symbols-outlined {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        width: 80px;
    }
}

.range-slider {
    position: relative;
    width: 80vmin;
    height: 20vmin;
}

.range-slider_input {
    width: 100%;
    position: absolute;
    top: 50%;
    z-index: 3;
    transform: translateY(-50%);
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 4px;
    opacity: 0;
    margin: 0;
}

.range-slider_input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 100px;
    height: 100px;
    cursor: pointer;
    border-radius: 50%;
    opacity: 0;
}

.range-slider_input::-moz-range-thumb {
    width: 14vmin;
    height: 14vmin;
    cursor: pointer;
    border-radius: 50%;
    opacity: 0;
}

.range-slider_thumb {
    width: 40px;
    height: 40px;
    border: 0.6vmin solid #303030;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transition: all ease-in 0.1s;
    transform: translateY(-50%);
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 4vmin;
    color: #303030;
    z-index: 2;
    .material-symbols-outlined {
        font-size: 40px;
        width: 40px;
    }
}

.green {
    color: green;
}
.orange {
    color: orange;
}
.red {
    color: red;
}

.range-slider_line {
    height: 0.5vmin;
    width: 100%;
    background-color: #303030;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    position: absolute;
    z-index: 1;
    transition: all ease-in 0.1s;
}

.range-slider_line-fill {
    position: absolute;
    height: 0.5vmin;
    width: 0;
    transition: all ease-in 0.1s;
    background-color: #e1e1e1;
}


@media only screen and (max-width: 700px) {
    .container {
        >.material-symbols-outlined {
            font-size: 30px;
            width: 60px;
        }
    }
    .range-slider_thumb {
        height: 30px;
        width: 30px;
        .material-symbols-outlined {
            font-size: 30px;
        }
    }

    .range-slider {
        height: 16vmin;
    }
}