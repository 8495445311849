.short-about-me {
    margin: 2vw;
    padding: 4vw;
    box-shadow: 0px 0px 8px -3px #ffff;
    border-radius: 20px;
    // background-color: #181818;
    display: flex;
    align-items: flex-end;
    background-image: radial-gradient(circle, #1e0d0d, #181818, #1d1c1c);

    .content {
        width: 80%;
        margin-right: 30px;

        .name {
            font-size: 6vw;
            font-weight: 800;
        }

        .description {
            margin-top: 5vw;
            font-size: 3vw;
            line-height: 1.5;
            width: 85%;
        }

        .work-together {
            font-family: sans-serif;
            margin-top: 5vw;
            font-size: 5vw;
            line-height: 0.8;
            font-weight: 600;

            .work {
                margin-left: 40px;
            }

            .together {
                margin-left: 80px;
                display: flex;
                align-items: center;
            }
        }
    }


    .social-media {
        width: 20%;
        font-size: 2.2vw;
        font-weight: 600;
    }
    .lets-talk-btn {
        margin-left: 45px;
    }
}

@media only screen and (max-width: 700px) {
    .short-about-me {
        flex-direction: column;
    }
    .lets-talk-btn {
        padding: 0px 15px !important;
        margin-left: 10px !important;
        width: 160px !important;
    }

    .content {
        width: 100% !important;
        margin-right: 0px !important;
    }

    .work-together {
        margin-bottom: 5vw;
        .together {
            margin-left: 50px !important;
        }
        .work {
            margin-left: 30px !important;
        }

    }

    .social-media {
        width: 100% !important;
        font-size: 4vw !important;
    }
}