$dark: #313c4d;
$light: #459399;

.ats-resume-container {
    .ats-resume-inner-container {
        padding: 60px;
        width: 70vw;
        border: 1px solid #fff;
        border-radius: 10px;
        margin: 40px auto 60px;
        background-color: #fff;
        color: $dark;

        &.download {
            width: 100vw;
            margin: unset;
            border-radius: 0px;
            box-sizing: border-box;
            margin-bottom: 0px;
            padding: 80px;
            font-size: 20px;

            .font-14 {
                font-size: 20px;
            }

            .font-18 {
                font-size: 24px;

                li {
                    font-size: 20px;
                }
            }

            .designation {
                margin-bottom: 10px;
            }

            .skills {
                font-size: 18px !important;
            }

            .seperator-title {
                font-size: 26px;
            }

            .portfolio-link {
                margin-bottom: 20px;
                font-weight: 500;
                color: $light !important;

                >span {
                    font-size: 20px;
                    margin-right: 5px;
                }
            }
        }

        a {
            // color: inherit;
            // text-decoration: none;
            display: flex;
            align-items: center;
            transition: all ease-in-out 0.1s;

            // &:hover {
            //     transform: scale(1.02);
            // }
        }

    }

    .download-resume {
        position: fixed;
        bottom: 20px;
        background-color: #fff;
        padding: 10px 20px;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0px 0px 4px 1px #747474;
        border-radius: 20px;
        font-weight: 600;
        transition: all ease-in-out 0.2s;
        text-decoration: none;
        color: inherit;
        cursor: pointer;
        display: flex;
        align-items: center;

        >div {
            margin-right: 10px;
        }

        &:hover {
            font-weight: 700;
            box-shadow: 0px 0px 10px 3px #747474;
        }
    }

    .ats-resume-heading {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

}