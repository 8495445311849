.service {
    background-image: repeating-linear-gradient(150deg, #141414 10%, #212121 30%, #040101 60%);
    padding: 4vw 6vw;

    .title {
        font-size: 2vw;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        white-space: wrap;

        .material-symbols-outlined {
            transition: all ease-in 0.3s;
            color: #5d5d5d;
            margin-top: 10px;
        }

        .view-detail {
            transition: all ease-in 0.2s;
            opacity: 0;
            margin-left: 25px;
            text-decoration: none;
            font-size: 1.5vw;
            margin-top: 6px;
            color: #5d5d5d;
        }

        &:hover {
            .view-detail {
                opacity: 1;
                // visibility: visible;
            }

            .material-symbols-outlined {
                transform: translateX(20px);
            }
        }
    }

    .items {
        padding: 0px;

        li {
            transition: all ease-in 0.2s;

            // background-image: repeating-linear-gradient(150deg, #141414, #212121 10%, #040101 20%);
            &:hover {
                background: #2d2c2ca6;
                transform: scale(1.05);
            }

            display: inline-flex;
            margin: 1.5vw 1vw;
            padding: 2vw;
            font-size: 2.5vw;
            font-weight: 800;
            border: 1px solid #5d5d5d;
            border-radius: 30px;
            width: fit-content;
            list-style: none;
        }
    }
}

@media only screen and (max-width: 700px) {
    .service .title {
        .material-symbols-outlined {
            margin-top: unset !important;
            font-size: 10px !important;
        }

        .view-detail {
            margin-top: unset;
        }
    }

    .service .title {
        font-size: 3vw;

        .view-detail {
            opacity: 1;
            font-size: 3vw;
            // visibility: visible;
        }

        .material-symbols-outlined {
            transform: translateX(10px);
        }
    }

    .service {
        margin-top: 30px !important;
        padding: 6vw 3vw !important;

        .items {
            margin-bottom: unset !important;
            li {
                padding: 1vw 2vw !important;
            }
        }
    }
}