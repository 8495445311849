.button {
    cursor: pointer;
    padding: 16px 20px;
    border-radius: 30px;
    width: 250px;
    font-weight: 600;
    font-size: 2vw;
    transition: all ease-in 0.2s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #eee8e8;
    box-shadow: 3px 3px 5px #1b1a1a, -3px -3px 5px #2b2b2b;
    border: none;
    color: #2b2b2b;
    letter-spacing: 2px;

    &:hover {
        box-shadow: inset 3px 3px 5px #1b1a1a, inset -3px -3px 5px #2b2b2b;
        transform: scale(0.98);
    }

    &:active {
        box-shadow: inset 3px 3px 5px #1b1a1a, inset -3px -3px 5px #2b2b2b;
        transform: scale(0.98);
    }
}

@media only screen and (max-width: 700px) {
    button {
        padding: 8px 10px !important;
        font-size: 3vw !important;
        width: 200px !important;
    }
}