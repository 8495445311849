.loader {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 100vh;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: #ffff;
    font-size: 5vw;
    transition: all ease-in 0.3s;
    font-weight: 800;
    background-image: radial-gradient(circle, #191919, #171616, #000000);

    &.show {
        top: 0;
    }

    &.hide {
        top: -100vh;
    }
}