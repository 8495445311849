.feedback {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-image: repeating-linear-gradient(90deg, #2e3243 56%, #2a2b2b 30%, #050707 60%);
    // background: linear-gradient(to left, #1d1313 0%, #101210 22%, #181616 51%, #1a1e1a 88%, #232828 100%);

    .title {
        font-size: 2vw;
        text-align: center;
        margin-bottom: 20px;
    }

    .description {
        font-size: 1.5vw;
        text-align: center;
        margin-top: 4vw;
    }


    .sucess-thumb {
        margin-top: 3vw;
        font-size: 4vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .feedback-inner-container {
        width: 50vw;
        background-color: #2b2c2ee0;
        box-sizing: border-box;
        padding: 30px 90px;
        margin: 60px 0px;
        border-radius: 20px;
        transition: all ease-in 0.2s;
        box-shadow: 0px 0px 5px 1px #4b4848;

        &:hover {
            box-shadow: 0px 0px 15px 3px #4b4848;
        }

        >button {
            width: 100%;
            border: 1px solid;
            border-radius: 10px;
            padding: 20px 40px;
        }

    }
}

@media only screen and (max-width: 700px) {
    .feedback {
        .title {
            font-size: 4vw;
        }

        >form {
            width: 80vw;
            padding: 20px 20px;

            button {
                width: 100% !important;
            }
        }
        .feedback-inner-container {
            width: 90vw;
            padding: 20px;
            margin: 30px 0px;
        }
    }
}