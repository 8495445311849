input,
textarea {
    font-family: sans-serif;
    box-sizing: border-box;
    padding: 20px 40px;
    outline: none;
    background: rgb(28, 28, 28);
    border: 1px solid #fff;
    border-radius: 10px;
    width: 100%;
    margin: 20px 0px;
    font-size: 2vw;
    color: #fff;
}

@media only screen and (max-width: 700px) {
    input, textarea {
        padding: 10px 20px;
        font-size: 4vw;
        margin: 10px 0px;
    }
}